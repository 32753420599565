/*
 * @Author: Abhi Bhatt
 * @Date:   2020-06-09 18:50:28
 * @Last Modified by:   abhi
 * @Last Modified time: 2024-07-24 16:22:20
 */
import React from 'react';
//import ReactDOM from 'react-dom';
import { Link, Redirect } from 'react-router-dom';
import $ from 'jquery';
import logo from '../../images/home-img/logo-small.png';
import HeaderService from './../../services/HeaderService/HeaderService';
import UserService from './../../services/UserService/UserService';
import Slider from '../Slider/Slider.js';
import TitleUnderline from '../TitleUnderline/TitleUnderline';
import TitleWithCircle from '../TitleWithCircle/TitleWithCircle';
import Accordion from '../Accordion/Accordion';
import './Header.style.css';
import { productHierarchy } from './categories.js';

// Put any other imports below so that CSS from your
// components takes precedence over default styles.
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuItems: [],
      fields: {},
      errors: {},
      error_message: '',
      signup_error_message: '',
      user_id: '',
      first_name: '',
      last_name: '',
      is_login: 0,
      is_logout: 0,
    };
    this.renderSiteLog = this.renderSiteLog.bind(this);
    this.renderNavBar = this.renderNavBar.bind(this);
    this.renderProductMenu = this.renderProductMenuTwo.bind(this);
    // this.renderProductMenuHardCoded =
    //   this.renderProductMenuHardCoded.bind(this);
    this.renderSignUpModal = this.renderSignUpModal.bind(this);
    this.renderLoginForm = this.renderLoginForm.bind(this);
    this.renderSignUpForm = this.renderSignUpForm.bind(this);
    this.headerService = new HeaderService();
    this.userService = new UserService();
    this.loginForm = this.loginForm.bind(this);
    this.renderLoggedinUser = this.renderLoggedinUser.bind(this);
    this.logout = this.logout.bind(this);
    this.logoutRedirect = this.logoutRedirect.bind(this);
    this.signUpForm = this.signUpForm.bind(this);
  }

  loginForm(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      $('#loginSubmit').attr('disabled', true);
      this.userService.login(this.state.fields).then((response) => {
        $('#loginSubmit').attr('disabled', false);
        this.setState({ error_message: '' });
        if (response.status === 200) {
          console.log(response.data);
          if (response.data.response == 'fail') {
            this.setState({ error_message: response.data.message });
          } else {
            localStorage.setItem('techtop_user_id', response.data.result[0].pk);
            localStorage.setItem(
              'techtop_user_first_name',
              response.data.result[0].fields.first_name
            );
            localStorage.setItem(
              'techtop_user_last_name',
              response.data.result[0].fields.last_name
            );
            localStorage.setItem('is_login', 1);
            this.setState({ user_id: response.data.result[0].pk });
            this.setState({
              first_name: response.data.result[0].fields.first_name,
            });
            this.setState({
              last_name: response.data.result[0].fields.last_name,
            });
            this.setState({ is_login: 1 });
            this.setState({ is_logout: 0 });
            window.location.reload();
          }
        }
      });
    }
  }

  signUpForm(e) {
    e.preventDefault();
    if (this.handleSignUpValidation()) {
      $('#btnSignUp').attr('disabled', true);
      this.userService.signUp(this.state.fields).then((response) => {
        $('#btnSignUp').attr('disabled', false);
        this.setState({ error_message: '' });
        if (response.status === 200) {
          console.log(response.data);
          if (response.data.response == 'fail') {
            this.setState({ signup_error_message: response.data.message });
          } else {
            $('#success_message').css('display', 'block');
            this.setState({ success_message: response.data.message });
            $('#myModal').modal('hide');
            setTimeout(
              function () {
                this.setState({ success_message: '' });
                $('#success_message').css('display', 'none');
              }.bind(this),
              5000
            );
          }
        }
      });
    }
  }

  logoutRedirect() {
    if (this.state.is_logout === 1) {
      return <Redirect to="/" />;
    }
  }

  handleSignUpValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['user_email']) {
      formIsValid = false;
      //errors["user_email"] = "Please enter email.";
      $('#user_email').css('backgroundColor', '#EAD0D1');
      $('#user_email').css('border-color', '#96151B');
    }

    if (!fields['encrypted_password']) {
      formIsValid = false;
      //errors["encrypted_password"] = "Please enter password.";
      $('#encrypted_password').css('border-color', '#96151B');
      $('#encrypted_password').css('backgroundColor', '#EAD0D1');
    }

    if (!fields['first_name']) {
      formIsValid = false;
      //errors["first_name"] = "Please enter email.";
      $('#first_name').css('backgroundColor', '#EAD0D1');
      $('#first_name').css('border-color', '#96151B');
    }

    if (!fields['last_name']) {
      formIsValid = false;
      //errors["last_name"] = "Please enter password.";
      $('#last_name').css('border-color', '#96151B');
      $('#last_name').css('backgroundColor', '#EAD0D1');
    }

    if (!fields['company']) {
      formIsValid = false;
      //errors["company"] = "Please enter password.";
      $('#company').css('border-color', '#96151B');
      $('#company').css('backgroundColor', '#EAD0D1');
    }

    if (!fields['address']) {
      formIsValid = false;
      //errors["last_name"] = "Please enter password.";
      $('#address').css('border-color', '#96151B');
      $('#address').css('backgroundColor', '#EAD0D1');
    }

    if (!fields['phone']) {
      formIsValid = false;
      //errors["last_name"] = "Please enter password.";
      $('#phone').css('border-color', '#96151B');
      $('#phone').css('backgroundColor', '#EAD0D1');
    }
    if (fields['encrypted_password'] !== fields['conf_password']) {
      formIsValid = false;
      $('#conf_password').css('border-color', '#96151B');
      $('#conf_password').css('backgroundColor', '#EAD0D1');
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleValidation() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields['email']) {
      formIsValid = false;
      errors['email'] = 'Please enter email.';
    }

    if (!fields['password']) {
      formIsValid = false;
      errors['password'] = 'Please enter password.';
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    e.preventDefault();
    let fields = this.state.fields;
    if (field.indexOf('data') !== -1) {
      var newField = field.split('.');
      console.log(newField[0]);
      if (newField[0] in fields) {
        if (newField[1] in fields[newField[0]]) {
          if (newField[2] in fields[newField[0]][newField[1]]) {
            fields[newField[0]][newField[1]][newField[2]] = e.target.value;
            fields[field] = e.target.value;
          } else {
            fields[newField[0]][newField[1]][newField[2]] = '';
            fields[newField[0]][newField[1]][newField[2]] = e.target.value;
            fields[field] = e.target.value;
          }
        } else {
          fields[newField[0]][newField[1]] = {};
          fields[newField[0]][newField[1]][newField[2]] = '';
          fields[newField[0]][newField[1]][newField[2]] = e.target.value;
          fields[field] = e.target.value;
        }
      } else {
        fields[newField[0]] = {};
        fields[newField[0]][newField[1]] = {};
        fields[newField[0]][newField[1]][newField[2]] = '';
        fields[newField[0]][newField[1]][newField[2]] = e.target.value;
        fields[field] = e.target.value;
      }
    } else {
      fields[field] = e.target.value;
    }
    this.setState({ fields });
  }

  logout() {
    this.userService.logout().then((response) => {
      console.log(response);
      this.setState({ is_logout: 1 });
      this.setState({ is_login: 0 });
    });
  }

  componentDidMount() {
    if (
      localStorage.getItem('techtop_user_id') != '' &&
      localStorage.getItem('techtop_user_id') != null
    ) {
      this.setState({ user_id: localStorage.getItem('techtop_user_id') });
      this.setState({
        first_name: localStorage.getItem('techtop_user_first_name'),
      });
      this.setState({
        last_name: localStorage.getItem('techtop_user_last_name'),
      });
      this.setState({ is_login: 1 });
    }

    this.headerService.getCategory().then((response) => {
      if (response.status === 200) {
        //console.log(response.data);
        this.setState({ menuItems: response.data.result });
      }
    });
    if (
      localStorage.getItem('show_message') != '' &&
      localStorage.getItem('show_message') === 1
    ) {
      $('#success_message').css('display', 'block');
      $('#success_message').html(localStorage.getItem('success_message'));
    } else {
      $('#success_message').css('display', 'none');
      $('#success_message').html('');
    }

    setTimeout(
      function () {
        //this.setState({success_message:''});
        //$('#success_message').css('display','none');
        localStorage.setItem('show_message', 0);
        localStorage.setItem('success_message', '');
      }.bind(this),
      10000
    );
  }

  renderSiteLog() {
    return (
      <React.Fragment>
        <div className="col-md-3 col-sm-3 col-lg-3 col-xl-3 col-12 d-none d-md-block mt-4">
          <a href={'/'}>
            <img
              src={logo}
              alt="Techtopind Electric Motors"
              className="d-block img-fluid mx-auto"
            />
          </a>
        </div>
      </React.Fragment>
    );
  }

  renderProductMenuTwo() {
    const nemaName = productHierarchy[0].name;
    const iecName = productHierarchy[1].name;
    const nemaCategories = productHierarchy[0].categories;
    const iecCategories = productHierarchy[1].categories;
    const accesoriesName = productHierarchy[2].name;
    const accesoriesCategories = productHierarchy[2].categories;

    return (
      <>
        <div class="header nema-col col-sm-7 col-12 px-0 d-flex flex-column justify-content-center align-items-center flex-nowrap py-3">
          <TitleUnderline linkTo={'#'} title={nemaName} />
          <div class="nema-cols d-flex row flex-nowrap align-items-start justify-content-center py-3 gap-4">
            {/* 1st sub column of nema */}
            <div className="first-sub-col header px-0 col-6 flex-shrink-1">
              {nemaCategories
                .slice(0, Math.ceil(nemaCategories.length / 2))
                .map((category) => {
                  const key = category.name.trim().replace(/\s/g, '-');
                  if (category.collapsable === true) {
                    return (
                      <Accordion
                        key={key}
                        name={category.name}
                        subItems={category.subCategories}
                        collapseId={`${key}`}
                      />
                    );
                  } else {
                    return (
                      <TitleWithCircle
                        key={key}
                        title={category.name}
                        linkTo={category.permalink}
                      />
                    );
                  }
                })}
            </div>

            {/* 2nd sub column of nema */}
            <div className="second-sub-col header first-sub-col px-0 col-6 flex-shrink-1">
              {nemaCategories
                .slice(Math.ceil(nemaCategories.length / 2))
                .map((category) => {
                  const key = category.name.trim().replace(/\s/g, '-');
                  if (category.collapsable === true) {
                    return (
                      <Accordion
                        key={key}
                        name={category.name}
                        subItems={category.subCategories}
                        collapseId={`${key}`}
                      />
                    );
                  } else {
                    return (
                      <TitleWithCircle
                        key={key}
                        title={category.name}
                        linkTo={category.permalink}
                      />
                    );
                  }
                })}
            </div>
          </div>
        </div>
        <div class="header col-sm-4 col-12 d-flex flex-column gap-4 justify-content-center align-items-center px-0 py-3">
          <div class="header px-0 text-center justify-content-start align-items-center d-flex flex-column">
            <TitleUnderline linkTo={'#'} title={iecName} />
            <div class="d-flex iec-col align-items-start text-start justify-content-start row w-75 pt-3">
              {iecCategories.map((category) => {
                const key = category.name.trim().replace(/\s/g, '-');
                if (category.collapsable === true) {
                  return (
                    <Accordion
                      key={key}
                      name={category.name}
                      subItems={category.subCategories}
                      collapseId={`${key}`}
                    />
                  );
                } else {
                  return (
                    <TitleWithCircle
                      key={key}
                      title={category.name}
                      linkTo={category.permalink}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div class="header acce-col px-0 text-center justify-content-start align-items-center d-flex flex-column">
            <TitleUnderline linkTo={'#'} title={accesoriesName} />
            <div class="d-flex align-items-start text-start justify-content-start">
              <ul className="list-unstyled h3 text-nowrap">
                {accesoriesCategories.map((subItem, index) => {
                  return (
                    <li key={index} className="list-unstyled">
                      <a href={subItem.permalink}>{subItem.name}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderLoggedinUser() {
    let first_name = this.state.first_name;
    let last_name = this.state.last_name;
    let is_login = this.state.is_login;
    if (is_login === 1) {
      return (
        <React.Fragment>
          <ul className="navbar-nav ml-auto black-bg">
            <li className="nav-item">
              <Link className="nav-link" to="">
                <i className="fas fa-user"></i> <br />
                {first_name}
                <br />
                {last_name}
              </Link>
              <a className="nav-link" href="#" onClick={this.logout}>
                <hr data-content="OR" className="hr-text" />
                Log out
              </a>
            </li>
          </ul>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <ul className="navbar-nav ml-auto black-bg">
            <li className="nav-item">
              <Link
                className="nav-link"
                to=""
                data-toggle="modal"
                data-target="#myModal"
              >
                <i className="fas fa-unlock"></i> <br />
                sign up
              </Link>
              <Link
                className="nav-link"
                to=""
                data-toggle="modal"
                data-target="#myModal"
              >
                <hr data-content="OR" className="hr-text" />
                Log in
              </Link>
            </li>
          </ul>
        </React.Fragment>
      );
    }
  }

  renderNavBar() {
    return (
      <React.Fragment>
        <div className="col-md-8 col-sm-8 col-lg-7 col-xl-7 col-12">
          <nav
            className="navbar navbar-expand-md navbar-light"
            data-toggle="sticky-onscroll"
          >
            <a className="navbar-brand d-block d-md-none" href={'/'}>
              <img
                src={logo}
                alt="Techtopind Electric Motors"
                className="d-block img-fluid"
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav mr-auto black-bg">
                <li
                  className="nav-item dropdown has-mega-menu"
                  style={{ position: 'static' }}
                >
                  <Link
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    to="#"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>Products</span>
                  </Link>
                  <div className="dropdown-menu header " id="dropdown">
                    <div className="products-dropdown d-flex flex-lg-row flex-column justify-content-center align-items-start gap-2 py-3 flex-wrap">
                      {this.renderProductMenuTwo()}
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link to={'/downloads'} className="nav-link">
                    <span>Downloads</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={'/news'} className="nav-link">
                    <span>News</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={'/contact'}>
                    <span>Contact Us</span>
                  </Link>
                </li>
                <li className="nav-item" style={{ width: '50%' }}>
                  <Link className="nav-link" to={'/track-shipment'}>
                    <span>Track your shipment</span>
                  </Link>
                </li>
              </ul>
              {this.renderLoggedinUser()}
            </div>
          </nav>
        </div>
      </React.Fragment>
    );
  }

  renderLoginForm() {
    return (
      <React.Fragment>
        <div className="col-md-6 col-sm-6 col-lg-7 col-xl-7 pr-0">
          <div className="black-bg h-100">
            <h3>Sign in</h3>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 col-12">
                <form
                  onSubmit={this.loginForm.bind(this)}
                  className="login-form"
                  id="sign_up_user"
                >
                  <span style={{ color: 'red' }}>
                    {this.state.error_message}
                  </span>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email-address"
                      placeholder="E-mail"
                      style={{ backgroundColor: '#FAFFBD' }}
                      id="email"
                      ref="email"
                      onChange={this.handleChange.bind(this, 'email')}
                      defaultValue={this.state.fields['email']}
                    />
                    <span style={{ color: 'red' }}>
                      {this.state.errors['email']}
                    </span>
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Password"
                      style={{ backgroundColor: '#FAFFBD' }}
                      id="password"
                      ref="password"
                      onChange={this.handleChange.bind(this, 'password')}
                      defaultValue={this.state.fields['password']}
                    />
                    <small>Password must be at least 8 characters long</small>
                    <span style={{ color: 'red' }}>
                      {this.state.errors['password']}
                    </span>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6 col-8">
                      <div className="checkbox">
                        <label htmlFor="remember-me">
                          <span>
                            <input
                              id="remember-me"
                              name="remember-me"
                              type="checkbox"
                            />
                          </span>{' '}
                          <span>Remember me</span>{' '}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-4 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        id="loginSubmit"
                      >
                        Login
                      </button>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-12 p-0">
                      <a href="/user/forgot-password" className="btn btn-link">
                        Forgot Your Password?
                      </a>
                      <a href="/user/confirmation" className="btn btn-link">
                        Didn't receive confirmation instructions?
                      </a>
                    </div>
                  </div>
                  <i className="fas fa-unlock"></i>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderSignUpForm() {
    return (
      <React.Fragment>
        <div className="col-md-6 col-sm-6 col-lg-5 col-xl-5 px-0">
          <div className="gray-bg h-100">
            <button type="button" className="close" data-dismiss="modal">
              ×
            </button>
            <h5>Sign up</h5>
            <form
              onSubmit={this.signUpForm.bind(this)}
              id="sign_up_user"
              className="mt-4"
            >
              <span style={{ color: 'red' }}>
                {this.state.signup_error_message}
              </span>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="E-mail"
                  ref="user_email"
                  id="user_email"
                  onChange={this.handleChange.bind(this, 'user_email')}
                  defaultValue={this.state.fields['user_email']}
                />
                <span style={{ color: 'red' }}>
                  {this.state.errors['user_email']}
                </span>
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Password"
                  ref="encrypted_password"
                  id="encrypted_password"
                  onChange={this.handleChange.bind(this, 'encrypted_password')}
                  defaultValue={this.state.fields['encrypted_password']}
                />
                <span style={{ color: 'red' }}>
                  {this.state.errors['encrypted_password']}
                </span>
              </div>
              <div className="form-group">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder="Password Confirmation"
                  ref="conf_password"
                  id="conf_password"
                  onChange={this.handleChange.bind(this, 'conf_password')}
                  defaultValue={this.state.fields['conf_password']}
                />
                <span style={{ color: 'red' }}>
                  {this.state.errors['conf_password']}
                </span>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="fname"
                  placeholder="First Name"
                  ref="first_name"
                  id="first_name"
                  onChange={this.handleChange.bind(this, 'first_name')}
                  defaultValue={this.state.fields['first_name']}
                />
                <span style={{ color: 'red' }}>
                  {this.state.errors['first_name']}
                </span>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="mName"
                  placeholder="Middle Name"
                  id="middle_initial"
                  ref="middle_initial"
                  onChange={this.handleChange.bind(this, 'middle_initial')}
                  defaultValue={this.state.fields['middle_initial']}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="lname"
                  placeholder="Last Name"
                  ref="last_name"
                  id="last_name"
                  onChange={this.handleChange.bind(this, 'last_name')}
                  defaultValue={this.state.fields['last_name']}
                />
                <span style={{ color: 'red' }}>
                  {this.state.errors['last_name']}
                </span>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="company"
                  placeholder="Company"
                  ref="company"
                  id="company"
                  onChange={this.handleChange.bind(this, 'company')}
                  defaultValue={this.state.fields['company']}
                />
                <span style={{ color: 'red' }}>
                  {this.state.errors['company']}
                </span>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="state"
                  placeholder="State"
                  ref="state"
                  id="state"
                  onChange={this.handleChange.bind(this, 'state')}
                  defaultValue={this.state.fields['state']}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="city"
                  placeholder="City"
                  ref="city"
                  id="city"
                  onChange={this.handleChange.bind(this, 'city')}
                  defaultValue={this.state.fields['city']}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="address"
                  placeholder="Address"
                  ref="address"
                  id="address"
                  onChange={this.handleChange.bind(this, 'address')}
                  defaultValue={this.state.fields['address']}
                />
                <span style={{ color: 'red' }}>
                  {this.state.errors['address']}
                </span>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="zip"
                  placeholder="Zip"
                  ref="zip"
                  id="zip"
                  onChange={this.handleChange.bind(this, 'zip')}
                  defaultValue={this.state.fields['zip']}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="number"
                  placeholder="Phone Number"
                  ref="phone"
                  id="phone"
                  onChange={this.handleChange.bind(this, 'phone')}
                  defaultValue={this.state.fields['phone']}
                />
                <span style={{ color: 'red' }}>
                  {this.state.errors['phone']}
                </span>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="number"
                  placeholder="Cell Phone Number"
                  ref="cell_phone"
                  id="cell_phone"
                  onChange={this.handleChange.bind(this, 'cell_phone')}
                  defaultValue={this.state.fields['cell_phone']}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="number"
                  placeholder="Direct Phone Number"
                  ref="direct_phone"
                  id="direct_phone"
                  onChange={this.handleChange.bind(this, 'direct_phone')}
                  defaultValue={this.state.fields['direct_phone']}
                />
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="fax"
                  placeholder="Fax"
                  ref="fax"
                  id="fax"
                  onChange={this.handleChange.bind(this, 'fax')}
                  defaultValue={this.state.fields['fax']}
                />
              </div>
              <div className="form-group text-right">
                <button
                  type="submit"
                  className="btn btn-primary"
                  id="btnSignUp"
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderSignUpModal() {
    return (
      <React.Fragment>
        <div className="modal fade" id="myModal">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <div className="row">
                  {this.renderLoginForm()}
                  {this.renderSignUpForm()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.logoutRedirect()}
        <header className="headerSection">
          <span
            className="container-fluid"
            id="success_message"
            style={{ color: 'red', display: 'none' }}
          >
            {this.state.success_message}
          </span>
          <div className="container-fluid">
            <div className="row">
              {this.renderSiteLog()}
              {this.renderNavBar()}
              {this.renderSignUpModal()}
            </div>
          </div>
        </header>
        <Slider />
      </React.Fragment>
    );
  }
}

export default Header;
